import './App.css';

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'flowbite';
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import 'bootstrap-icons/font/bootstrap-icons.css';

import Home from './components/home/home';
import App_Development from './components/web&app/js/App Development'
import Web_design from './components/web&app/js/website-design'
import Ecommerce from './components/web&app/js/ecommerce'
import Video_Photo from './components/Video & Photography/js/Video_Photo'
import Social_media_marketing from './components/DigitalMarketing/js/social-media-marketing'

import Design from './components/Design/js/Design'
import About from './components/about/js/main'
import Contact from './components/Contact/js/main'
import PageNotFound from './components/page-not-found'
import GoogleAd from './components/GoogleAd'
// import 'aos/dist/aos.css'; 
import "./components/cursor"

function App() {
  return (
    <div   class='app' id='app'>
  
<canvas id="fluid"></canvas>

    <Router>

    
    
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="app_Development" element={<App_Development />} />
        <Route path="web_design" element={<Web_design />} />
        <Route path="ecommerce" element={<Ecommerce />} />
        <Route path="video_Photo" element={<Video_Photo />} />
        <Route path="social_media_marketing" element={<Social_media_marketing />} />
        <Route path="design" element={<Design />} />
        <Route path="about" element={<About />} />
        <Route path="contact" element={<Contact />} />
        
        <Route   path='*' element={<PageNotFound />} />
      </Routes>
      </Router>
 
   </div>
  );
}
AOS.init();

export default App;
